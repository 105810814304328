<template>
  <div class="own-container">
    <div class="bread">个人中心 / <span>修改密码</span></div>
    <el-row class="form-body">
      <h5>修改密码</h5>
      <el-form ref="userForm" :model="formData" label-width="80px" :label-position="labelPosition" :rules="ruleUser" >
          <el-form-item prop="oldpwd" label="旧密码" >
<!--            <el-input v-model="formData.oldpwd" type="password" placeholder="请输入就密码"  show-password></el-input>-->
            <el-input v-model="formData.oldpwd" type="password" placeholder="请输入旧密码"  show-password maxlength="20"></el-input>
          </el-form-item>
          <el-form-item prop="password" label="新密码" >
            <el-input v-model="formData.password" type="password" placeholder="请输入新密码" show-password maxlength="20"></el-input>
          </el-form-item>
          <el-form-item prop="conPass" label="确认密码" >
            <el-input v-model="formData.conPass" type="password" placeholder="请再次输入新密码" show-password maxlength="20"></el-input>
          </el-form-item>

      </el-form>
      <div class="line">
        <el-button type="default" size="small">取消</el-button>
        <el-button type="primary" size="small" @click="handleChangePass()">保存</el-button>
      </div>
    </el-row>
  </div>
</template>

<script>
import {updateDetail, getUserDetail} from '@/utils/api/own'

export default {
  name: "password",
  data(){
    return{
      labelPosition:"left",
      formData:{
        oldpwd:"",
        password:"",
        conPass:""
      },
      ruleUser:{
        oldpwd:[
          { required: true, message: '请输入旧密码', trigger: 'blur' },
        ],
        password:[
          { required: true, message: '请输入新密码', trigger: 'blur' },
        ],
        conPass:[
          { required: true, message: '请确认新密码', trigger: 'blur' },
        ],
      }
    }
  },
  created() {
  },
  methods: {
    handleChangePass(){
      if(this.formData.password !== this.formData.conPass){
        this.$message.error("新密码与确认密码不同")
        return false
      }
      this.formData.cmd = 3
      updateDetail(this.formData).then(res => {
        if(res.code == 0){
          this.$message.success("修改成功")
          this.$refs['userForm'].resetFields()
        }else{
          this.$message.error(res.msg)
        }
      })
    }
  }

}
</script>

<style  lang="less">
.form-body{
  background-color: #FFFFFF;
  padding: 30px 30px 60px 30px;
  h5{
    height: 22px;
    font-size: 16px;
    font-weight: 800;
    color: #333333;
    line-height: 22px;
    margin-bottom: 30px;
  }
  .el-form-item__label{
    font-size: 14px;
    font-weight: 400;
    color: #333333;
  }
  .el-form-item__content {
    line-height: 42px;
    .el-input{
      width: 360px;
      .el-input__inner {
        width: 100%;
        height: 42px;
        line-height: 42px;
        background: #FAFAFA;
        border-radius: 2px;
        border: none;
      }
    }

  }
  .line{
    text-align: right;
    border-top: 1px solid #F1F1F1;
    padding-top: 30px;
  }
}

</style>

